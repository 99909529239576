import { template as template_fbeeb741fdc44d47af39cd7a12ab94ce } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_fbeeb741fdc44d47af39cd7a12ab94ce(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;

import { template as template_cfe1f211e9bd41bab0bb1b2d3b576afe } from "@ember/template-compiler";
import { notEq } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
const FKControlConditionalContentItem = template_cfe1f211e9bd41bab0bb1b2d3b576afe(`
  <div
    class={{concatClass
      "form-kit__conditional-display-content"
      (if (notEq @name @activeName) "hidden")
    }}
  >
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlConditionalContentItem;

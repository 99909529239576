import { template as template_130bf0b489e046e89f04fed2f7026cab } from "@ember/template-compiler";
const FKControlMenuContainer = template_130bf0b489e046e89f04fed2f7026cab(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_19f6e7ca39b74df4be4fb937f96869b4 } from "@ember/template-compiler";
const WelcomeHeader = template_19f6e7ca39b74df4be4fb937f96869b4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

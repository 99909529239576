import { template as template_54e3310ef1b54e1c9158bc8daf37e2a6 } from "@ember/template-compiler";
const FKLabel = template_54e3310ef1b54e1c9158bc8daf37e2a6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
